var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "scroll-container payment-due-date" },
    [
      _c("CategoryTitle", { attrs: { category: _vm.category } }),
      _vm.list.length > 0
        ? _c(
            "div",
            { staticClass: "invoice-list pt-1" },
            [
              _c(
                "v-row",
                { staticClass: "header payment-table" },
                [
                  _c("v-col", { attrs: { cols: "2", md: "3" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.state")))
                    ])
                  ]),
                  _vm.$vuetify.breakpoint.mdAndUp
                    ? _c("v-col", { attrs: { cols: "2" } }, [
                        _c("strong", [
                          _vm._v(_vm._s(_vm.$t("paymentDueDate.docDate")))
                        ])
                      ])
                    : _vm._e(),
                  _c("v-col", { attrs: { cols: "3", md: "1" } }, [
                    _c("strong", [_vm._v("Nº")])
                  ]),
                  _c("v-col", { attrs: { cols: "2", align: "end" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.amount")))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "2", align: "end" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.weld")))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.expiration")))
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.list, function(item) {
                return _c(
                  "v-row",
                  { key: item.paymentDueDateId, staticClass: "payment-table" },
                  [
                    _c(
                      "v-col",
                      { attrs: { cols: "2", md: "3" } },
                      [
                        _c("v-checkbox", {
                          staticClass: "mt-0",
                          attrs: {
                            "input-value": item.selected,
                            "hide-details": "",
                            disabled: !item.paymentDueDateStatusId == 1
                          },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              return _vm.update(item)
                            }
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function() {
                                  return [
                                    _vm.$vuetify.breakpoint.mdAndUp
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                class: [
                                                  "payment-duedate-status-" +
                                                    item.statusID
                                                ],
                                                attrs: { small: "" }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        `paymentDueDate.status[${item.statusID}]`
                                                      )
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            ),
                                            item.paymentDueDateStatusId > 1
                                              ? _c("span", [
                                                  _vm._v(
                                                    " Tran. n: " +
                                                      _vm._s(
                                                        item.orderTransactionId
                                                      ) +
                                                      " "
                                                  )
                                                ])
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      : _c("div")
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          )
                        })
                      ],
                      1
                    ),
                    _vm.$vuetify.breakpoint.mdAndUp
                      ? _c("v-col", { attrs: { cols: "2" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.$dayjs(item.docDate).format("DD-MM-YYYY")
                            )
                          )
                        ])
                      : _vm._e(),
                    _c("v-col", { attrs: { cols: "3", md: "1" } }, [
                      _vm._v(_vm._s(item.docReferenceNumber))
                    ]),
                    _c("v-col", { attrs: { cols: "2", align: "end" } }, [
                      _vm._v(_vm._s(_vm.$n(item.docAmount, "currency")))
                    ]),
                    _c("v-col", { attrs: { cols: "2", align: "end" } }, [
                      item.paymentDueDateStatusId == 1
                        ? _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$n(item.paymentAmount, "currency"))
                            )
                          ])
                        : _vm._e()
                    ]),
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _vm._v(
                        _vm._s(_vm.$dayjs(item.dueDate).format("DD-MM-YYYY"))
                      )
                    ])
                  ],
                  1
                )
              }),
              _c(
                "v-row",
                { staticClass: "total-pay payment-table" },
                [
                  _c("v-col", { attrs: { cols: "6", align: "end" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.totalToPay")) + ":")
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _vm._v(_vm._s(_vm.$n(_vm.total, "currency")))
                  ])
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-col", { attrs: { cols: "6", align: "end" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("paymentDueDate.selectedToPay")) + ":"
                      )
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _vm._v(_vm._s(_vm.$n(_vm.totalSelected, "currency")))
                  ])
                ],
                1
              ),
              _vm.totalSelected > 0
                ? _c(
                    "div",
                    { staticClass: "pt-4" },
                    [
                      _c("h4", { staticClass: "secondary--text" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("paymentDueDate.paySelected")) +
                            " "
                        )
                      ]),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    outlined: "",
                                    block: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.pay(1)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("paymentDueDate.payWithCard")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              attrs: { cols: "12", sm: "6", md: "4", lg: "3" }
                            },
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    color: "secondary",
                                    outlined: "",
                                    block: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.pay(9)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t("paymentDueDate.payByTransfer")
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            2
          )
        : _c(
            "v-card",
            {
              staticClass: "pa-2 text-center w-100",
              attrs: { light: "", outlined: "" }
            },
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v(_vm._s(_vm.$t("paymentDueDate.noAvailablePayments")))
              ])
            ],
            1
          ),
      _vm.transactions.length > 0
        ? _c(
            "div",
            { staticClass: "pt-4" },
            [
              _c("h4", { staticClass: "secondary--text" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("paymentDueDate.listOfTransactions")) +
                    " "
                )
              ]),
              _c(
                "v-row",
                { staticClass: "header payment-table" },
                [
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("strong", [_vm._v("Nº")])
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("strong", [
                      _vm._v(_vm._s(_vm.$t("paymentDueDate.transactions.date")))
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "3" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("paymentDueDate.transactions.state"))
                      )
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "2" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("paymentDueDate.transactions.payment"))
                      )
                    ])
                  ]),
                  _c("v-col", { attrs: { cols: "3", align: "end" } }, [
                    _c("strong", [
                      _vm._v(
                        _vm._s(_vm.$t("paymentDueDate.transactions.amount"))
                      )
                    ])
                  ])
                ],
                1
              ),
              _vm._l(_vm.transactions, function(t) {
                return _c(
                  "v-row",
                  {
                    key: "t_" + t.orderTransactionId,
                    staticClass: "payment-table"
                  },
                  [
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _c("strong", [_vm._v(_vm._s(t.orderTransactionId))])
                    ]),
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _vm._v(_vm._s(t.addDate))
                    ]),
                    _c(
                      "v-col",
                      { attrs: { cols: "3" } },
                      [
                        _c(
                          "v-chip",
                          {
                            class: [
                              "transaction-status-" + t.transactionStatusId
                            ],
                            attrs: { small: "" }
                          },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  `paymentDueDate.transactions.transactionStatus[${t.transactionStatusId}]`
                                )
                              )
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("v-col", { attrs: { cols: "2" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            `paymentDueDate.transactions.paymentType[${t.paymentTypeId}]`
                          )
                        )
                      )
                    ]),
                    _c("v-col", { attrs: { cols: "3", align: "end" } }, [
                      _c("strong", [
                        _vm._v(_vm._s(_vm.$n(t.authorizedAmount, "currency")))
                      ])
                    ])
                  ],
                  1
                )
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }